import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

const ContactPage = () => (
  <Layout>
    <SEO
      title="Contact"
      keywords={[`bai chan kheo`, `contact`, `developer`, `software`, `react`]}
    />
    <h1>Contact.</h1>
    <p>Contact goes here.</p>
  </Layout>
);

export default ContactPage;
